.fading-text {
    opacity: 0;
    transition: opacity 1s ease-in-out; /* Adjust the transition duration as needed */
  }
  
  .fade-out {
    opacity: 0;
  }
  
  .fade-in {
    opacity: 1;
  }