@font-face {
  font-family: "Caprasimo";   /*Can be any text*/
  src: local("Caprasimo-Regular"),
    url("./fonts/Caprasimo-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "SigmarOne";   /*Can be any text*/
  src: local("SigmarOne-Regular"),
    url("./fonts/SigmarOne-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header {
  height: 6%;
  color: white;
  background-color: #1a699c;
}

.page {
  width: 100%;
  height: 94%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.center-div {
  align-items: center;
  justify-content: center;
}

.list {
  width: 100%;
  padding: 10px;
  overflow: auto;
  background-color: #f1f1f1;
}

.list-item {
  margin-bottom: 10px;
}

.box {
  border-style: solid;
  border-color: lightgrey;
  border-width: 1px;
  padding: 20px;
  box-shadow: -5px 5px 10px 0px rgba(0, 0, 0, 0.75);
}
